import React from 'react';
import { css } from '@emotion/core';

import FullWidthBox from 'components/FullWidthBox';
// import SEO from 'components/SEO/SEO';

const Privacy = () => {
  // const markdownTypesAllowed = ['text', 'strong', 'delete', 'emphasis', 'link'];
  return (
    <>
      {/* <SEO
        follow={page.seo.follow}
        title={page.seo.title}
        description={page.seo.description}
        image={page.seo.description}
        pathname={location.pathname}
      /> */}
      <FullWidthBox
        css={css`
          margin-top: 100px;
        `}
        sectionwidth="54rem"
      >
        <article>
          <FullWidthBox>
            <h1>DATENSCHUTZERKLÄRUNG</h1>
            <p>
              Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und
              Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend
              kurz „Daten“) innerhalb unseres Onlineangebotes und der mit ihm
              verbundenen Webseiten, Funktionen und Inhalte sowie externen
              Onlinepräsenzen, wie z.B. unser Social Media Profile auf
              (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im
              Hinblick auf die verwendeten Begrifflichkeiten, wie z.B.
              „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die
              Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).
            </p>
            <br />
            <br />
            <h4>Verantwortlicher</h4>
            <br />
            Pfefferminz Film GmbH
            <br />
            <br />
            Höhenweg 4a
            <br />
            87437 Kempten
            <br />
            Deutschland
            <br />
            <br />
            Tel.: +49 176 820 20 912
            <br />
            E-Mail: info@pfefferminzmich.de
            <br />
            <br />
            Vertretungsberechtigte Gesellschafter:
            <br />
            Raphael Weber
            <br />
            <br />
            <h4>Arten der verarbeiteten Daten:</h4>
            <br />
            <ul>
              <li>Bestandsdaten (z.B. Namen, Adressen).</li>
              <li>Kontaktdaten (z.B. E-Mail, Telefonnummern).</li>
              <li>Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos).</li>
              <li>
                Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
                Zugriffszeiten).
              </li>
              <li>
                Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                IP-Adressen).
              </li>
            </ul>
            <br />
            <h4>Kategorien betroffener Personen</h4>
            <br />
            <p>
              Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen
              wir die betroffenen Personen zusammenfassend auch als „Nutzer“).
            </p>
            <br />
            <h4>Zweck der Verarbeitung</h4>
            <br />
            <ul>
              <li>
                urverfügungstellung des Onlineangebotes, seiner Funktionen und
                Inhalte.
              </li>
              <li>
                Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern
              </li>
              <li>Sicherheitsmaßnahmen. - Reichweitenmessung/Marketing</li>
            </ul>
            <br />
            <h4>Verwendete Begrifflichkeiten</h4>
            <br />
            <p>
              „Personenbezogene Daten“ sind alle Informationen, die sich auf
              eine identifizierte oder identifizierbare natürliche Person (im
              Folgenden „betroffene Person“) beziehen; als identifizierbar wird
              eine natürliche Person angesehen, die direkt oder indirekt,
              insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
              zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
              (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen
              identifiziert werden kann, die Ausdruck der physischen,
              physiologischen, genetischen, psychischen, wirtschaftlichen,
              kulturellen oder sozialen Identität dieser natürlichen Person
              sind.
            </p>
            <p>
              „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter
              Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im
              Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit
              und umfasst praktisch jeden Umgang mit Daten.  „Pseudonymisierung“
              die Verarbeitung personenbezogener Daten in einer Weise, dass die
              personenbezogenen Daten ohne Hinzuziehung zusätzlicher
              Informationen nicht mehr einer spezifischen betroffenen Person
              zugeordnet werden können, sofern diese zusätzlichen Informationen
              gesondert aufbewahrt werden und technischen und organisatorischen
              Maßnahmen unterliegen, die gewährleisten, dass die
              personenbezogenen Daten nicht einer identifizierten oder
              identifizierbaren natürlichen Person zugewiesen werden.
            </p>
            <p>
              „Profiling“ jede Art der automatisierten Verarbeitung
              personenbezogener Daten, die darin besteht, dass diese
              personenbezogenen Daten verwendet werden, um bestimmte persönliche
              Aspekte, die sich auf eine natürliche Person beziehen, zu
              bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung,
              wirtschaftliche Lage, Gesundheit, persönliche Vorlieben,
              Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder
              Ortswechsel dieser natürlichen Person zu analysieren oder
              vorherzusagen.
            </p>
            <p>
              Als „Verantwortlicher“ wird die natürliche oder juristische
              Person, Behörde, Einrichtung oder andere Stelle, die allein oder
              gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung
              von personenbezogenen Daten entscheidet, bezeichnet.
            </p>
            <p>
              „Auftragsverarbeiter“ eine natürliche oder juristische Person,
              Behörde, Einrichtung oder andere Stelle, die personenbezogene
              Daten im Auftrag des Verantwortlichen verarbeitet.
            </p>
            <br />
            <h4>Maßgebliche Rechtsgrundlagen</h4>
            <br />
            Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen
            unserer Datenverarbeitungen mit. Sofern die Rechtsgrundlage in der
            Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die
            Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs.
            1 lit. a und Art. 7 DSGVO, die Rechtsgrundlage für die Verarbeitung
            zur Erfüllung unserer Leistungen und Durchführung vertraglicher
            Maßnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b
            DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung
            unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO,
            und die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer
            berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Für den
            Fall, dass lebenswichtige Interessen der betroffenen Person oder
            einer anderen natürlichen Person eine Verarbeitung personenbezogener
            Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
            Rechtsgrundlage.
            <br />
            <br />
            <h4>Sicherheitsmaßnahmen</h4>
            <br />
            <p>
              Wir treffen nach Maßgabe des Art. 32 DSGVO unter Berücksichtigung
              des Stands der Technik, der Implementierungskosten und der Art,
              des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie
              der unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des
              Risikos für die Rechte und Freiheiten natürlicher Personen,
              geeignete technische und organisatorische Maßnahmen, um ein dem
              Risiko angemessenes Schutzniveau zu gewährleisten.
            </p>
            <p>
              Zu den Maßnahmen gehören insbesondere die Sicherung der
              Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
              Kontrolle des physischen Zugangs zu den Daten, als auch des sie
              betreffenden Zugriffs, der Eingabe, Weitergabe, der Sicherung der
              Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren
              eingerichtet, die eine Wahrnehmung von Betroffenenrechten,
              Löschung von Daten und Reaktion auf Gefährdung der Daten
              gewährleisten. Ferner berücksichtigen wir den Schutz
              personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl
              von Hardware, Software sowie Verfahren, entsprechend dem Prinzip
              des Datenschutzes durch Technikgestaltung und durch
              datenschutzfreundliche Voreinstellungen (Art. 25 DSGVO).
            </p>
            <br />
            <h4
              css={css`
                max-width: 100%;
              `}
            >
              Zusammenarbeit mit Auftragsverarbeitern und Dritten
            </h4>
            <br />
            <p>
              Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen
              Personen und Unternehmen (Auftragsverarbeitern oder Dritten)
              offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf
              die Daten gewähren, erfolgt dies nur auf Grundlage einer
              gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der Daten an
              Dritte, wie an Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b
              DSGVO zur Vertragserfüllung erforderlich ist), Sie eingewilligt
              haben, eine rechtliche Verpflichtung dies vorsieht oder auf
              Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von
              Beauftragten, Webhostern, etc.).   Sofern wir Dritte mit der
              Verarbeitung von Daten auf Grundlage eines sog.
              „Auftragsverarbeitungsvertrages“ beauftragen, geschieht dies auf
              Grundlage des Art. 28 DSGVO.{' '}
            </p>
            <br />
            <h4>Übermittlungen in Drittländer</h4>
            <br />
            <p>
              Sofern wir Daten in einem Drittland (d.h. außerhalb der
              Europäischen Union (EU) oder des Europäischen Wirtschaftsraums
              (EWR)) verarbeiten oder dies im Rahmen der Inanspruchnahme von
              Diensten Dritter oder Offenlegung, bzw. Übermittlung von Daten an
              Dritte geschieht, erfolgt dies nur, wenn es zur Erfüllung unserer
              (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung,
              aufgrund einer rechtlichen Verpflichtung oder auf Grundlage
              unserer berechtigten Interessen geschieht. Vorbehaltlich
              gesetzlicher oder vertraglicher Erlaubnisse, verarbeiten oder
              lassen wir die Daten in einem Drittland nur beim Vorliegen der
              besonderen Voraussetzungen der Art. 44 ff. DSGVO verarbeiten. D.h.
              die Verarbeitung erfolgt z.B. auf Grundlage besonderer Garantien,
              wie der offiziell anerkannten Feststellung eines der EU
              entsprechenden Datenschutzniveaus (z.B. für die USA durch das
              „Privacy Shield“) oder Beachtung offiziell anerkannter spezieller
              vertraglicher Verpflichtungen (so genannte
              „Standardvertragsklauseln“).
            </p>
            <br />
            <h4>Rechte der betroffenen Personen</h4>
            <br />
            <p>
              Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
              betreffende Daten verarbeitet werden und auf Auskunft über diese
              Daten sowie auf weitere Informationen und Kopie der Daten
              entsprechend Art. 15 DSGVO.  Sie haben entsprechend. Art. 16 DSGVO
              das Recht, die Vervollständigung der Sie betreffenden Daten oder
              die Berichtigung der Sie betreffenden unrichtigen Daten zu
              verlangen.  Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu
              verlangen, dass betreffende Daten unverzüglich gelöscht werden,
              bzw. alternativ nach Maßgabe des Art. 18 DSGVO eine Einschränkung
              der Verarbeitung der Daten zu verlangen.  Sie haben das Recht zu
              verlangen, dass die Sie betreffenden Daten, die Sie uns
              bereitgestellt haben nach Maßgabe des Art. 20 DSGVO zu erhalten
              und deren Übermittlung an andere Verantwortliche zu fordern.   Sie
              haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der
              zuständigen Aufsichtsbehörde einzureichen.
            </p>
            <br />
            <h4>Widerrufsrecht</h4>
            <br />
            <p>
              Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3
              DSGVO mit Wirkung für die Zukunft zu widerrufen
            </p>
            <br />
            <h4>Widerspruchsrecht</h4>
            <br />
            <p>
              Sie können der künftigen Verarbeitung der Sie betreffenden Daten
              nach Maßgabe des Art. 21 DSGVO jederzeit widersprechen. Der
              Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke
              der Direktwerbung erfolgen.
            </p>
            <br />
            <h4
              css={css`
                max-width: 100%;
              `}
            >
              Cookies und Widerspruchsrecht bei Direktwerbung
            </h4>
            <br />
            <p>
              Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern
              der Nutzer gespeichert werden. Innerhalb der Cookies können
              unterschiedliche Angaben gespeichert werden. Ein Cookie dient
              primär dazu, die Angaben zu einem Nutzer (bzw. dem Gerät auf dem
              das Cookie gespeichert ist) während oder auch nach seinem Besuch
              innerhalb eines Onlineangebotes zu speichern. Als temporäre
              Cookies, bzw. „Session-Cookies“ oder „transiente Cookies“, werden
              Cookies bezeichnet, die gelöscht werden, nachdem ein Nutzer ein
              Onlineangebot verlässt und seinen Browser schließt. In einem
              solchen Cookie kann z.B. der Inhalt eines Warenkorbs in einem
              Onlineshop oder ein Login-Status gespeichert werden. Als
              „permanent“ oder „persistent“ werden Cookies bezeichnet, die auch
              nach dem Schließen des Browsers gespeichert bleiben. So kann z.B.
              der Login-Status gespeichert werden, wenn die Nutzer diese nach
              mehreren Tagen aufsuchen. Ebenso können in einem solchen Cookie
              die Interessen der Nutzer gespeichert werden, die für
              Reichweitenmessung oder Marketingzwecke verwendet werden. Als
              „Third-Party-Cookie“ werden Cookies bezeichnet, die von anderen
              Anbietern als dem Verantwortlichen, der das Onlineangebot
              betreibt, angeboten werden (andernfalls, wenn es nur dessen
              Cookies sind spricht man von „First-Party Cookies“).
            </p>
            <p>
              Wir können temporäre und permanente Cookies einsetzen und klären
              hierüber im Rahmen unserer Datenschutzerklärung auf.
            </p>
            <p>
              Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner
              gespeichert werden, werden sie gebeten die entsprechende Option in
              den Systemeinstellungen ihres Browsers zu deaktivieren.
              Gespeicherte Cookies können in den Systemeinstellungen des
              Browsers gelöscht werden. Der Ausschluss von Cookies kann zu
              Funktionseinschränkungen dieses Onlineangebotes führen.
            </p>
            <p>
              Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des
              Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der
              Dienste, vor allem im Fall des Trackings, über die
              US-amerikanische Seite http://www.aboutads.info/choices/ oder die
              EU-Seite http://www.youronlinechoices.com/ erklärt werden. Des
              Weiteren kann die Speicherung von Cookies mittels deren
              Abschaltung in den Einstellungen des Browsers erreicht werden.
              Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen
              dieses Onlineangebotes genutzt werden können.
            </p>
            <br />
            <h4>Löschung von Daten</h4>
            <br />
            <p>
              Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17
              und 18 DSGVO gelöscht oder in ihrer Verarbeitung eingeschränkt.
              Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich
              angegeben, werden die bei uns gespeicherten Daten gelöscht, sobald
              sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der
              Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
              Sofern die Daten nicht gelöscht werden, weil sie für andere und
              gesetzlich zulässige Zwecke erforderlich sind, wird deren
              Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und
              nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die
              aus handels- oder steuerrechtlichen Gründen aufbewahrt werden
              müssen.
            </p>
            <p>
              Nach gesetzlichen Vorgaben in Deutschland, erfolgt die
              Aufbewahrung insbesondere für 10 Jahre gemäß §§ 147 Abs. 1 AO, 257
              Abs. 1 Nr. 1 und 4, Abs. 4 HGB (Bücher, Aufzeichnungen,
              Lageberichte, Buchungsbelege, Handelsbücher, für Besteuerung
              relevanter Unterlagen, etc.) und 6 Jahre gemäß § 257 Abs. 1 Nr. 2
              und 3, Abs. 4 HGB (Handelsbriefe).
            </p>
             
            <p>
              Nach gesetzlichen Vorgaben in Österreich erfolgt die Aufbewahrung
              insbesondere für 7 J gemäß § 132 Abs. 1 BAO
              (Buchhaltungsunterlagen, Belege/Rechnungen, Konten, Belege,
              Geschäftspapiere, Aufstellung der Einnahmen und Ausgaben, etc.),
              für 22 Jahre im Zusammenhang mit Grundstücken und für 10 Jahre bei
              Unterlagen im Zusammenhang mit elektronisch erbrachten Leistungen,
              Telekommunikations-, Rundfunk- und Fernsehleistungen, die an
              Nichtunternehmer in EU-Mitgliedstaaten erbracht werden und für die
              der Mini-One-Stop-Shop (MOSS) in Anspruch genommen wird.
            </p>
            <br />
            <br />
            <h4>Agenturdienstleistungen</h4>
            <br />
            <p>
              Wir verarbeiten die Daten unserer Kunden im Rahmen unserer
              vertraglichen Leistungen zu denen konzeptionelle und strategische
              Beratung, Kampagnenplanung, Software- und
              Designentwicklung/-beratung oder Pflege, Umsetzung von Kampagnen
              und Prozessen/ Handling, Serveradministration, Datenanalyse/
              Beratungsleistungen und Schulungsleistungen gehören.
            </p>
            <p>
              Hierbei verarbeiten wir Bestandsdaten (z.B. Kundenstammdaten, wie
              Namen oder Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern),
              Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos),
              Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit), Zahlungsdaten
              (z.B. Bankverbindung, Zahlungshistorie), Nutzungs- und Metadaten
              (z.B. im Rahmen der Auswertung und Erfolgsmessung von
              Marketingmaßnahmen). Besondere Kategorien personenbezogener Daten
              verarbeiten wir grundsätzlich nicht, außer wenn diese Bestandteile
              einer beauftragten Verarbeitung sind. Zu den Betroffenen gehören
              unsere Kunden, Interessenten sowie deren Kunden, Nutzer,
              Websitebesucher oder Mitarbeiter sowie Dritte. Der Zweck der
              Verarbeitung besteht in der Erbringung von Vertragsleistungen,
              Abrechnung und unserem Kundenservice. Die Rechtsgrundlagen der
              Verarbeitung ergeben sich aus Art. 6 Abs. 1 lit. b DSGVO
              (vertragliche Leistungen), Art. 6 Abs. 1 lit. f DSGVO (Analyse,
              Statistik, Optimierung, Sicherheitsmaßnahmen). Wir verarbeiten
              Daten, die zur Begründung und Erfüllung der vertraglichen
              Leistungen erforderlich sind und weisen auf die Erforderlichkeit
              ihrer Angabe hin. Eine Offenlegung an Externe erfolgt nur, wenn
              sie im Rahmen eines Auftrags erforderlich ist. Bei der
              Verarbeitung der uns im Rahmen eines Auftrags überlassenen Daten
              handeln wir entsprechend den Weisungen der Auftraggeber sowie der
              gesetzlichen Vorgaben einer Auftragsverarbeitung gem. Art. 28
              DSGVO und verarbeiten die Daten zu keinen anderen, als den
              auftragsgemäßen Zwecken.
            </p>
            <p>
              Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs-
              und vergleichbarer Pflichten. die Erforderlichkeit der
              Aufbewahrung der Daten wird alle drei Jahre überprüft; im Fall der
              gesetzlichen Archivierungspflichten erfolgt die Löschung nach
              deren Ablauf (6 J, gem. § 257 Abs. 1 HGB, 10 J, gem. § 147 Abs. 1
              AO). Im Fall von Daten, die uns gegenüber im Rahmen eines Auftrags
              durch den Auftraggeber offengelegt wurden, löschen wir die Daten
              entsprechend den Vorgaben des Auftrags, grundsätzlich nach Ende
              des Auftrags.
            </p>
            <br />
             <br />
            <h4>Kontaktaufnahme</h4>
            <br />
            <p>
              Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail,
              Telefon oder via sozialer Medien) werden die Angaben des Nutzers
              zur Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art.
              6 Abs. 1 lit. b) DSGVO verarbeitet. Die Angaben der Nutzer können
              in einem Customer-Relationship-Management System ("CRM System")
              oder vergleichbarer Anfragenorganisation gespeichert werden.
            </p>
            <p>
              Wir löschen die Anfragen, sofern diese nicht mehr erforderlich
              sind. Wir überprüfen die Erforderlichkeit alle zwei Jahre; Ferner
              gelten die gesetzlichen Archivierungspflichten.
            </p>
            <br />
             <br />
            <h4>Newsletter - Mailchimp</h4>
            <br />
            <p>
              Der Versand der Newsletter erfolgt mittels des
              Versanddienstleisters „MailChimp“, einer
              Newsletterversandplattform des US-Anbieters Rocket Science Group,
              LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA. Die
              Datenschutzbestimmungen des Versanddienstleisters können Sie hier
              einsehen: https://mailchimp.com/legal/privacy/. The Rocket Science
              Group LLC d/b/a MailChimp ist unter dem Privacy-Shield-Abkommen
              zertifiziert und bietet hierdurch eine Garantie, das europäisches
              Datenschutzniveau einzuhalten
              (https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&status=Active).
              Der Versanddienstleister wird auf Grundlage unserer berechtigten
              Interessen gem. Art. 6 Abs. 1 lit. f DSGVO und eines
              Auftragsverarbeitungsvertrages gem. Art. 28 Abs. 3 S. 1 DSGVO
              eingesetzt.
            </p>
            <p>
              Der Versanddienstleister kann die Daten der Empfänger in
              pseudonymer Form, d.h. ohne Zuordnung zu einem Nutzer, zur
              Optimierung oder Verbesserung der eigenen Services nutzen, z.B.
              zur technischen Optimierung des Versandes und der Darstellung der
              Newsletter oder für statistische Zwecke verwenden. Der
              Versanddienstleister nutzt die Daten unserer Newsletterempfänger
              jedoch nicht, um diese selbst anzuschreiben oder um die Daten an
              Dritte weiterzugeben.
            </p>
            <br />
             <br />
            <h4>Hosting und E-Mail-Versand</h4>
            <br />
            <p>
              Die von uns in Anspruch genommenen Hosting-Leistungen dienen der
              Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und
              Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
              Datenbankdienste, E-Mail-Versand, Sicherheitsleistungen sowie
              technische Wartungsleistungen, die wir zum Zwecke des Betriebs
              dieses Onlineangebotes einsetzen.
            </p>
            <p>
              Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten,
              Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta-
              und Kommunikationsdaten von Kunden, Interessenten und Besuchern
              dieses Onlineangebotes auf Grundlage unserer berechtigten
              Interessen an einer effizienten und sicheren Zurverfügungstellung
              dieses Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art.
              28 DSGVO (Abschluss Auftragsverarbeitungsvertrag).
            </p>
            <br />
             <br />
            <h4>Google Analytics</h4>
            <br />
            <p>
              {' '}
              Wir setzen auf Grundlage unserer berechtigten Interessen (d.h.
              Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb
              unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO)
              Google Analytics, einen Webanalysedienst der Google LLC („Google“)
              ein. Google verwendet Cookies. Die durch das Cookie erzeugten
              Informationen über Benutzung des Onlineangebotes durch die Nutzer
              werden in der Regel an einen Server von Google in den USA
              übertragen und dort gespeichert.
            </p>
            <p>
              Google ist unter dem Privacy-Shield-Abkommen zertifiziert und
              bietet hierdurch eine Garantie, das europäische Datenschutzrecht
              einzuhalten
              (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active).
            </p>
            <p>
              Google wird diese Informationen in unserem Auftrag benutzen, um
              die Nutzung unseres Onlineangebotes durch die Nutzer auszuwerten,
              um Reports über die Aktivitäten innerhalb dieses Onlineangebotes
              zusammenzustellen und um weitere, mit der Nutzung dieses
              Onlineangebotes und der Internetnutzung verbundene
              Dienstleistungen, uns gegenüber zu erbringen. Dabei können aus den
              verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer erstellt
              werden.
            </p>
            <p>
              Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung
              ein. Das bedeutet, die IP-Adresse der Nutzer wird von Google
              innerhalb von Mitgliedstaaten der Europäischen Union oder in
              anderen Vertragsstaaten des Abkommens über den Europäischen
              Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle
              IP-Adresse an einen Server von Google in den USA übertragen und
              dort gekürzt.
            </p>
            <p>
              Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht
              mit anderen Daten von Google zusammengeführt. Die Nutzer können
              die Speicherung der Cookies durch eine entsprechende Einstellung
              ihrer Browser-Software verhindern; die Nutzer können darüber
              hinaus die Erfassung der durch das Cookie erzeugten und auf ihre
              Nutzung des Onlineangebotes bezogenen Daten an Google sowie die
              Verarbeitung dieser Daten durch Google verhindern, indem sie das
              unter folgendem Link verfügbare Browser-Plugin herunterladen und
              installieren: http://tools.google.com/dlpage/gaoptout?hl=de.
            </p>
            <p>
              Weitere Informationen zur Datennutzung durch Google, Einstellungs-
              und Widerspruchsmöglichkeiten, erfahren Sie in der
              Datenschutzerklärung von Google
              (https://policies.google.com/technologies/ads) sowie in den
              Einstellungen für die Darstellung von Werbeeinblendungen durch
              Google (https://adssettings.google.com/authenticated).
            </p>
            <p>
              Die personenbezogenen Daten der Nutzer werden nach 14 Monaten
              gelöscht oder anonymisiert.
            </p>
            <br />
             <br />
            <h4>Google Universal Analytics</h4>
            <br />
            <p>
              Wir setzen Google Analytics in der Ausgestaltung als
              „Universal-Analytics“ ein. „Universal Analytics“ bezeichnet ein
              Verfahren von Google Analytics, bei dem die Nutzeranalyse auf
              Grundlage einer pseudonymen Nutzer-ID erfolgt und damit ein
              pseudonymes Profil des Nutzers mit Informationen aus der Nutzung
              verschiedener Geräten erstellt wird (sog.
              „Cross-Device-Tracking“).
            </p>
            <br />
            <br />
            <h4>Onlinepräsenzen in sozialen Medien</h4>
            <br />
            <p>
              Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
              Plattformen, um mit den dort aktiven Kunden, Interessenten und
              Nutzern kommunizieren und sie dort über unsere Leistungen
              informieren zu können. Beim Aufruf der jeweiligen Netzwerke und
              Plattformen gelten die Geschäftsbedingungen und die
              Datenverarbeitungsrichtlinien deren jeweiligen Betreiber.
            </p>
            <p>
              Soweit nicht anders im Rahmen unserer Datenschutzerklärung
              angegeben, verarbeiten wir die Daten der Nutzer sofern diese mit
              uns innerhalb der sozialen Netzwerke und Plattformen
              kommunizieren, z.B. Beiträge auf unseren Onlinepräsenzen verfassen
              oder uns Nachrichten zusenden.
            </p>
            <br />
             <br />
            <h4>Einbindung von Diensten und Inhalten Dritter</h4>
            <br />
            <p>
              Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer
              berechtigten Interessen (d.h. Interesse an der Analyse,
              Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes
              im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder
              Serviceangebote von Drittanbietern ein, um deren Inhalte und
              Services, wie z.B. Videos oder Schriftarten einzubinden
              (nachfolgend einheitlich bezeichnet als “Inhalte”).
            </p>
            <p>
              Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte,
              die IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse
              die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse
              ist damit für die Darstellung dieser Inhalte erforderlich. Wir
              bemühen uns nur solche Inhalte zu verwenden, deren jeweilige
              Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte
              verwenden. Drittanbieter können ferner so genannte Pixel-Tags
              (unsichtbare Grafiken, auch als "Web Beacons" bezeichnet) für
              statistische oder Marketingzwecke verwenden. Durch die
              "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den
              Seiten dieser Website ausgewertet werden. Die pseudonymen
              Informationen können ferner in Cookies auf dem Gerät der Nutzer
              gespeichert werden und unter anderem technische Informationen zum
              Browser und Betriebssystem, verweisende Webseiten, Besuchszeit
              sowie weitere Angaben zur Nutzung unseres Onlineangebotes
              enthalten, als auch mit solchen Informationen aus anderen Quellen
              verbunden werden.
            </p>
            <br />
             <br />
            <h4>Vimeo</h4>
            <br />
            <p>
              Wir können die Videos der Plattform “Vimeo” des Anbieters Vimeo
              Inc., Attention: Legal Department, 555 West 18th Street New York,
              New York 10011, USA, einbinden.
              Datenschutzerklärung: https://vimeo.com/privacy. WIr weisen darauf
              hin, dass Vimeo Google Analytics einsetzen kann und verweisen
              hierzu auf die Datenschutzerklärung
              (https://www.google.com/policies/privacy) sowie
              Opt-Out-Möglichkeiten für Google-Analytics
              (http://tools.google.com/dlpage/gaoptout?hl=de) oder die
              Einstellungen von Google für die Datennutzung zu Marketingzwecken
              (https://adssettings.google.com/.).
            </p>
            <br />
            <br />
            <h4>Google Fonts</h4>
            <br />
            <p>
              Wir binden die Schriftarten ("Google Fonts") des Anbieters Google
              LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein.
              Datenschutzerklärung: https://www.google.com/policies/privacy/,
              Opt-Out: https://adssettings.google.com/authenticated.
            </p>
            <br />
             <br />
            <h4>Typekit-Schriftarten von Adobe</h4>
            <br />
            <p>
              Wir setzen auf Grundlage unserer berechtigten Interessen (d.h.
              Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb
              unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO)
              externe "Typekit"-Schriftarten des Anbieters Adobe Systems
              Software Ireland Limited, 4-6 Riverwalk, Citywest Business Campus,
              Dublin 24, Republic of Ireland ein. Adobe ist unter dem
              Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine
              Garantie, das europäische Datenschutzrecht einzuhalten
              (https://www.privacyshield.gov/participant?id=a2zt0000000TNo9AAG&status=Active).
            </p>
            <br />
             <br />
            <h4>Instagram</h4>
            <br />
            <p>
              Innerhalb unseres Onlineangebotes können Funktionen und Inhalte
              des Dienstes Instagram, angeboten durch die Instagram Inc., 1601
              Willow Road, Menlo Park, CA, 94025, USA, eingebunden werden.
              Hierzu können z.B. Inhalte wie Bilder, Videos oder Texte und
              Schaltflächen gehören, mit denen Nutzer Inhalte dieses
              Onlineangebotes innerhalb von Instagram teilen können. Sofern die
              Nutzer Mitglieder der Plattform Instagram sind, kann Instagram den
              Aufruf der o.g. Inhalte und Funktionen den dortigen Profilen der
              Nutzer zuordnen. Datenschutzerklärung von
              Instagram: http://instagram.com/about/legal/privacy/.
            </p>
            <br />
            <p>
              Erstellt mit Datenschutz-Generator.de von RA Dr. Thomas Schwenke
            </p>
            <br />
          </FullWidthBox>
        </article>
      </FullWidthBox>
    </>
  );
};

export default Privacy;
